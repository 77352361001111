.confetti-wrapper > div {
  position: absolute;
}

.red {
  background-color: #E94A3F;
}

.yellow {
  background-color: #FAA040;
}

.blue {
  background-color: #5FC9F5;
}
