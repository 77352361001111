.dev-info {
  position: absolute;
  width: 100%;
  z-index: 100;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: normal;

  &:focus {
    outline-style: none;
  }

  details {

    summary {
      text-transform: uppercase;
      cursor: pointer;

      &:focus {
        outline-style: none;
      }
    }

    code {
      display: block;
      padding: 4px 8px;
      color: #38b12f;
      background: rgba(0, 0, 0, 0.6);

      &:focus {
        outline-style: none;
      }
    }

    &:focus {
      outline-style: none;
    }
  }

  .env-development {
    padding: 4px 8px;
    color: white;
    background-color: green;
    opacity: 0.7;
  }

  .env-marvin {
    @extend .env-development;
    background-color: orange;
  }

  .env-production {
    @extend .env-development;
    background-color: red;
  }

}
