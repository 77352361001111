@import "~survey-jquery/survey.css";
@import "variables";
@import "dev-info";
@import "~jquery-ui/themes/base/theme.css";
@import "~jquery-ui/themes/base/datepicker.css";

html {
  height: 100%;
  overflow: hidden;

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: auto;
    font-size: 16px;
    background: #fafafa;
    -webkit-overflow-scrolling: touch;

    button, input[type="button"], input[type="reset"], input[type="submit"] {
      -webkit-appearance: none;
    }

    .change-phone {
      font-size: 12px;
      cursor: pointer;
      color: $brand-primary;
    }

    .sv_main {
      margin: 40px auto 0;
      max-width: 800px;
      border: 4px solid #eee;

      @media (max-width: 992px) {
        width: 80%;
        border: 0 none;
      }

        .sv_container {
          color: $brand-secondary;

          .sv_header {
            padding: 24px;
            background: $brand-primary;

            h3 {
              font-size: 36px;
              font-weight: lighter;
              color: #fff;
            }
          }

          .sv_body {
            padding: 24px;
            border-top: 0 none;

            .sv_progress {
              border-radius: 8px;

              .sv_progress_bar {
                border-radius: 8px;
                background: $brand-primary;

                & > span {
                  top: -2em;
                  font-size: 12px;
                  color: $gray600;
                }
              }
            }

            input[type="button"], button {
              padding: 8px 16px;
              font-size: 16px;
              font-weight: normal;
              text-transform: uppercase;
              background: $brand-primary;
              transition: background .15s ease-in-out;

              &:hover, &:hover {
                background-color: #1797be;
              }

              &:focus, &:focus {
                outline-style: none;
              }
            }

            .sv_q_file_remove_button {
              padding: 4px;
              height: 30px;
              font-size: 14px;
              line-height: 22px;
              background-color: $gray500;

              &:hover {
                background-color: $gray600;
              }
            }

            label, input[type="checkbox"], input[type="radio"] {
              cursor: pointer;
            }

            .sv_p_root {

              .sv_row {
                border: 0 none;

                .sv_q {

                  input[type="text"], input[type="email"], input[type="password"], input[type="tel"] {
                    margin: 0 0 16px;
                    padding: 0 16px;
                    width: 100%;
                    height: 48px;
                    border-radius: $base-radius;
                  }

                  input.sv_q_other {
                    margin: 0 16px 16px;
                    width: calc(100% - 32px);
                  }

                  .sv_select_wrapper {

                    select {
                      height: 48px;
                      cursor: pointer;
                      border-radius: $base-radius;
                    }

                    &::before {
                      padding: 23px;
                      border-top-right-radius: $base-radius;
                      border-bottom-right-radius: $base-radius;
                      background-color: $brand-primary;
                    }
                  }

                  .sv_q_title, .sv_p_title {
                    margin: 24px 0;
                    padding: 0;
                    font-size: 24px;
                  }

                  .sv_q_radiogroup, .sv_q_checkbox {
                    margin: 0 0 4px;
                    padding: 0;
                    border: 1px solid #eee;
                    border-radius: $base-radius;

                    &.checked, &.checked {
                      background: #fafafa;
                    }
                  }

                  .sv_q_radiogroup_label, .sv_q_checkbox_label, label.sv_q_checkbox {
                      display: block;
                      padding: 16px;
                  }

                  .sv_q_matrix_dynamic, .sv_q_matrix, .sv_q_matrix_dropdown {

                    th, td {
                      padding: 16px;
                    }
                  }

                  .sv_matrix_dynamic_button {
                    @extend .sv_q_file_remove_button;
                  }

                  .sv_q_rating {

                    .sv_q_rating_item {

                      .sv_q_rating_item_text:hover {
                        border-color: $brand-primary;
                      }

                      &.active .sv_q_rating_item_text {
                        border-color: $brand-primary;
                        background-color: $brand-primary;
                      }
                    }
                  }

                  .sv_q_imgsel {
                    margin-bottom: 1.5em;

                    .sv_q_imgsel_label {

                      & > div {
                        padding: 8px;

                        &:hover {
                          -webkit-box-shadow: 0px 0px 10px 0px rgba(35,184,229,0.25);
                          -moz-box-shadow: 0px 0px 10px 0px rgba(35,184,229,0.25);
                          box-shadow: 0px 0px 10px 0px rgba(35,184,229,0.25);
                        }
                      }
                    }

                    &.checked {
                      .sv_q_imgsel_label > div {
                        border-color: $brand-primary;
                        background-color: $brand-primary;
                      }
                    }
                  }
                }
              }

              & > .sv_row:nth-child(2n) {
                background: #fff;
              }
            }

            .sv_nav {
              padding: 24px 0;
              min-height: 56px;
            }
          }
        }

        .sv_q_other input:focus, .sv_q_text_root:focus, .sv_q_dropdown_control:focus,  input[type="text"]:focus, select:focus, textarea:focus {
            border: 1px solid $brand-primary;
        }
    }

    /* All RTL css should be here */
    .rtl {
      & * {
        direction: rtl;
      }

      /* Fix header text align */
      .sv_main .sv_container .sv_header h3 {
        text-align: right;
      }

      /* Fix drop down label */
      .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper select {
        padding-left: 2.5em;
        padding-right: calc( 46px + 1em );
      }

      /* Rating boxes */
      .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item:not(:first-child) .sv_q_rating_item_text:not(:hover) {
        border-left-color: #e7e7e7;
        border-right-color: transparent;
      }

      /* Prev And Next button */
      .sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
        float: right;
      }

      .sv_main .sv_container .sv_body .sv_nav .sv_start_btn, .sv_main .sv_container .sv_body .sv_nav .sv_next_btn, .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
        float: left;
      }

      .sv_main .sv_container .sv_body .sv_nav .sv_start_btn, .sv_main .sv_container .sv_body .sv_nav .sv_next_btn, .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
        float: left;
      }
    }
  }
}
